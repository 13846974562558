<template>
  <div class="messages content-padding">
    <TableOverview
      kind="message"
      :page-title="labels.messages_overview"
      :searcheable="true"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getMessagesMt"
      :items="items"
      :isDataLoaded="isDataLoaded"
    />
    <router-view
      name="create_message"
      modal-id="create-message"
      kind="message"
      :route-back="{ name: 'messages' }"
      :title="labels.create_new_message"
      :title-placeholder="labels.title"
      :text-placeholder="labels.text"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'text']"
      @entity-created="messageCreated"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import TableOverview from "@/views/Dashboard/Common/TableOverview";
const colors = require("/colors.config.json");
export default {
  name: "Overview",
  components: {
    TableOverview,
  },
  data(){
    return {
      items: {},
      isDataLoaded: false
    }
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      return [
        {
          label: this.labels.create_new,
          variant: "btn-purple",
          action: () => {
            if (this.$route.name !== "create-message") {
              this.$router.push({
                name: "create-message",
                query: { page: this.$route.query.page },
              });
            }
          },
        },
      ];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.name,
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 80%",
            whiteSpace: "nowrap",
          },
        ],
        minTableWidth: "300px",
        actionsWidth: "1 1 20%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.open,
            variant: "btn-purple",
            styles: {
              padding: "8px 15px",
              fontWeight: "700",
              selfAlign: "left",
            },
            action: (data) =>
              this.$router.push({
                name: "message-settings",
                params: { id: data._id },
              }),
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("message", {
      getMessages: "getList",
    }),
    messageCreated() {
      this.getMessagesMt(this.$route.query.page);

      let page = this.$route.query.page;
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getMessages(data)
        .then((res) => {
          if (res.success) {
            console.log();
            this.$router.push({
              name: "message-settings",
              params: { id: res.data.data[res.data.data.length - 1]._id },
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    getMessagesMt(page) {
			this.isDataLoaded = false
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getMessages(data)
        .then((res) => {
          if (res.success) {
            this.items = res.data.items
            this.isDataLoaded = true
            this.setTablePagination(res.data.pages);
            if (
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getMessagesMt();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .table-card {
    &__thead {
      font-family: "Oswald", sans-serif;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 55px;
      color: $light_text;
    }

    &-row {
      &__item {
        word-break: break-word;
      }
    }
  }
}
</style>
